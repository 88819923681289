<template>
<span>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 635.125 735.01385" :width="width" :height="height" :style="{top: topPx, left: leftPx}">
  <g transform="translate(-2.9062 12.958)">
    <path d="m319.88 38.031c-30.271 51.771-71.399 100.14-128.5 127.38 23.14 34.352 35.855 36.263 47.781 40.594-28.93 35.515-74.955 77.677-125.98 97.299 15.872 16.578 25.44 25.63 41.232 38.045-41.285 65.471-93.701 85.059-146.5 110.38 174.65 184.34 469.62 143.39 625.12 2.25-0.0604-0.7144-0.45242-1.3558-0.6875-2.0312-55.192-26.412-89.62-27.911-146.75-110.59 8.9596-7.1142 32.574-25.545 39.192-44.612-52.776-23.624-103.63-55.983-123.97-90.732 16.789-6.1651 30.329-13.621 47.781-40.594-51.413-26.031-89.817-72.05-128.72-127.38z" :fill="fill1" fill-rule="evenodd" :stroke="stroke1" stroke-opacity=".79618" stroke-width="10"/>
    <path d="m56.048 494.75c161.67 85.744 363.76 62.989 439.15 11.331-42.674-27.325-66.116-91.834-80.187-141.34-7.7042 15.763-170.52 3.9393-204.83-24.53 27.059-0.55951 170.68 21.763 224.67-11.131-21.212-8.1743-58.358-79.69-72.83-119.65-0.34002-0.98412-1.6324-1.7717-2.9153-1.7766-18.166-0.97875-46.092 0.09346-75.835-24.969 26.607-0.95872 64.103 5.2211 91.795-4.5869-21.134-31.39-43.067-87.423-55.336-134.01 33.113 49.275 73.6 89.627 123.42 121.14 14.549 8.4026-38.365 39.318-45.944 39.363 25.335 51.723 101.29 84.803 123.24 95.415-4.4503 15.024-17.796 28.661-37.003 41.002 38.545 70.997 86.858 82.455 145.42 113.08-16.022 29.422-86.799 61.783-137.65 89.3-94.012 50.873-362.57 36.709-435.17-48.637z" :fill="fill2"/>
  </g>
  </svg>
</span>
</template>

<script>
import ThemeMixin from '../theme_mixin'

export default {
  name: 'tree',

  mixins: [ThemeMixin]
}
</script>
